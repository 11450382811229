<template>
  <div class="messages">
    <div class="card card-full-height-desktop">
      <div class="card-body inner-scroll">
        <template v-if="hasAccess">
          <div class="feat-desc">
            <p>{{ $t('desc_feat_messages') }}</p>
          </div>
          <div v-if="isFetched && listMessages.length === 0" class="row">
            <div class="d-flex m-auto flex-column">
              <NotFound />
            </div>
          </div>
          <div v-if="listMessages.length" class="messages-wrapper">
            <div class="sidebar-wrapper">
              <div
                v-for="message in listMessages"
                :key="'list-' + message.id"
                class="message-excerpt"
                :class="{ active: message.id === currentMessage.id }"
                @click="onSelectMessage(message)"
              >
                <span class="parent-name"
                  ><strong>{{ message.user.name }}</strong></span
                >
                <span v-if="message.activity" class="activity-name">{{
                  message.activity.name
                }}</span>
                <span class="date">{{ message.date }}</span>
                <span class="lastMessage">{{ message.message }}</span>
              </div>
            </div>
            <div class="message-details">
              <div class="message-details-wrapper">
                <div v-if="currentMessage" class="message-details-header">
                  <h3>
                    {{ currentMessage.user.name }}
                    <span v-if="currentMessage.activity">- {{ currentMessage.activity.name }}</span>
                  </h3>
                  <span class="date">{{ currentMessage.date }}</span>
                  <br />
                  <p>{{ currentMessage.message }}</p>
                </div>
                <div v-if="threadMessages.length" class="message-thread">
                  <div
                    v-for="m in threadMessages"
                    :key="m.id"
                    :class="{
                      own: m.by_provider,
                      external: !m.by_provider,
                      active: m.id === currentMessage.id,
                    }"
                  >
                    <div v-if="m.by_provider">
                      <strong>{{ $t('You') }}:</strong>
                    </div>
                    <div v-else>
                      <strong>{{ m.user.name }}:</strong>
                    </div>
                    <span>{{ m.message }}</span>
                  </div>
                </div>
              </div>
              <div class="new-message">
                <div v-if="isAllowedToReply">
                  <em>{{
                    $t(
                      'Remember that once the message is sent you will have to wait for a response from the parent before replying again.'
                    )
                  }}</em>
                  <br />
                  <b-form-group class="mt-1" :label="$t('Message')" label-for="message">
                    <b-form-textarea id="message" v-model="newMessage" class="mt-1" rows="3" />
                  </b-form-group>
                  <b-form-group>
                    <b-button
                      size="sm"
                      variant="primary"
                      class="btn-tour-finish float-right"
                      @click="handleFormSubmit"
                    >
                      {{ $t('Send') }}
                    </b-button>
                  </b-form-group>
                </div>
                <div v-else class="wait-for-message">
                  <em>{{
                    $t(
                      'Please wait until the user replies to your message in order to reply back to this message.'
                    )
                  }}</em>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-else class="row">
          <div class="col-md-4">
            <p class="feature-text">{{ $t('blocked_feat_messaging_1') }}</p>
            <p class="feature-text">{{ $t('blocked_feat_messaging_2') }}</p>
          </div>
          <div class="col-md-8">
            <img
              src="@/assets/images/screenshots/messaging.webp"
              alt="Screenshot of the messaging page"
              class="screenshot"
            />
          </div>
        </div>
      </div>
      <div v-if="!hasAccess" class="card-footer footer-shadow">
        <router-link to="/subscribe" class="btn btn-toddl">
          {{ $t('upgrade_plan_cta') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BFormTextarea, BFormGroup } from 'bootstrap-vue'

import NotFound from '@/components/NotFound'
import userCan from '@/libs/user-can'
import MessageService from '@/services/MessageService'

export default {
  name: 'Messages',
  components: {
    NotFound,
    BButton,
    BFormGroup,
    BFormTextarea,
  },
  data() {
    return {
      MessageService: new MessageService(),
      items: [],
      currentMessage: null,
      threadMessages: [],
      listMessages: [],
      isFetched: false,
      newMessage: '',
    }
  },
  computed: {
    isAllowedToReply() {
      return (
        this.currentMessage &&
        (this.threadMessages.length === 0 ||
          !this.threadMessages[this.threadMessages.length - 1].by_provider)
      )
    },
    hasAccess() {
      return userCan(this.$store.state.auth.user.plan, 'messages')
    },
  },
  mounted() {
    if (this.hasAccess) {
      this.getMessages()
    }
  },
  methods: {
    async getMessages() {
      this.isFetched = false
      this.currentMessage = null
      this.threadMessages = []
      this.items = []
      this.listMessages = []
      const messages = await this.MessageService.index()

      messages.forEach(message => {
        let date = new Date(message.created_at)
        let ret = {
          message: message.message,
          id: message.id,
          user: message.user,
          by_provider: message.by_provider,
          activity: message.activity || null,
          read: message.read,
          thread: message.thread_id,
          date: date.toLocaleDateString('es-ES'),
          updated_at: message.updated_at,
        }
        if (!ret.thread) {
          this.listMessages.push(ret)
        }
        this.items.push(ret)
      })

      this.currentMessage = this.listMessages[0]
      this.listMessages = this.listMessages.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1))
      this.threadMessages = [
        ...this.items
          .filter(el => el.thread === this.currentMessage.id)
          .sort((a, b) => (a.date < b.date ? 1 : -1)),
      ]
      this.isFetched = true
    },
    onSelectMessage: function (message) {
      this.currentMessage = message
      this.threadMessages = [
        ...this.items
          .filter(el => el.thread === message.id)
          .sort((a, b) => (a.date < b.date ? 1 : -1)),
      ]
    },
    handleFormSubmit: function () {
      let payload = {
        message: this.newMessage,
        thread_id: this.currentMessage.id,
        activity_id: this.currentMessage.activity ? this.currentMessage.activity.id : null,
        user_id: this.currentMessage.activity ? null : this.currentMessage.user_id,
        client_id: this.currentMessage.user.id,
      }
      this.MessageService.create(payload)
        .then(() => {
          this.getMessages()
          this.newMessage = ''
        })
        .catch(err => {
          console.error(err)
        })
    },
  },
}
</script>

<style scoped>
.screenshot {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 10;
}

.feature-text {
  font-size: 1.2rem;
  color: #333;
}

.card-full-height-desktop {
  height: calc(100vh - 130px);
}

.messages-wrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.sidebar-wrapper {
  flex-direction: column;
  flex: 0 0 40%;
  max-width: 40%;
  height: 65vh;
  overflow-y: scroll;
}
.message-excerpt {
  padding: 1.5em 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  max-width: 100%;
}

.message-excerpt .date {
  color: #444;
}

.message-excerpt .activity-name {
  color: #6bc4c5 !important;
}

.message-excerpt.active {
  background-color: #f3f3f3;
}

.message-excerpt span {
  display: block;
}

.message-thread {
  border-top: 1px solid #ededed;
  padding: 1rem 0;
  white-space: break-spaces;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.message-details {
  flex: 1;
  max-width: 58%;
  height: 65vh;
  overflow-y: scroll;
  padding-right: 1rem;
}
.message-details-wrapper {
  margin-bottom: 1rem;
}
.new-message {
  border-top: 1px solid #ededed;
  padding: 1rem 0;
}

.external {
  text-align: right;
}

@media (max-width: 648px) {
  .card-full-height-desktop {
    height: auto;
  }
  .messages-wrapper {
    flex-direction: column;
  }

  .sidebar-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0;
    overflow-x: auto;
    max-width: 100%;
  }

  .message-excerpt {
    min-width: fit-content;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .card-body {
    overflow: hidden;
  }
  .feat-desc {
    padding: 0.5em;
    font-size: 0.95em;
    margin-bottom: 0;
    margin-top: 0.5em;
  }

  .new-message {
    margin-bottom: 2rem;
  }
  .new-message em {
    font-size: 0.85em;
  }

  .message-details {
    flex: 1;
    max-width: 100%;
  }

  .lastMessage {
    display: none !important;
  }
}
</style>
