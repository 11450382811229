<template>
  <div class=" mt-5">
    <img class="m-auto"
         style="height: 250px" 
         :src="require('@/assets/images/provider/not-found.svg')"
         alt="Not found image">
    <h4 class="mt-2 text-center">{{ $t('No result found') }}</h4>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
