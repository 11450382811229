const ACCESS_LEVEL_BY_PLAN = {
  restricted: 0,
  plan_basic: 1,
  plan_pro: 2,
  plan_proplus: 3,
}

export default function (userPlan = 'restricted', requestedPermission) {
  try {
    const requiredAccessLevel = getRequiredAccessLevel(requestedPermission)

    return ACCESS_LEVEL_BY_PLAN[userPlan] >= requiredAccessLevel
  } catch (err) {
    console.error(err)
    return false
  }
}

/**
 * Returns the required access level for a given requested permission.
 *
 * @param {string} requestedPermission - The permission for which the access level is requested.
 * @return {number} The required access level for the given permission.
 */
function getRequiredAccessLevel(requestedPermission) {
  switch (requestedPermission) {
    case 'bookings':
    case 'contacts':
    case 'contacts_edit':
    case 'activities_create':
      return 1
    case 'widget':
    case 'payments':
    case 'serp_priority':
    case 'contacts_whatsapp':
      return 2
    case 'pro_plus_awesome_feature':
      return 3
    case 'messages':
    default:
      return 0
  }
}
